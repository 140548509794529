"use strict";

const {UtilityExtension} = require("./common_utility_extension");
const VERSION_SUFFIX = "Version";

let singletonInstance;

/**
 * @typedef ICustomLabelOptions
 * @property defaultName {string} The default name of that record. Usually required for "Add" operations, when the ID
 * and name doesn't exist yet.
 * @property useCustomId {boolean} If true, looks for a field called "customID" or "customId" to display instead of
 * the combination of type code and id.
 * @property separator {string} The separator used to split between the ID and the Name.
 * @property isTypeCodeTranslated {boolean} If true, assumes the type code is translated.
 * @property usage {CUSTOM_ID_USAGE} The way the custom id will be used. The default value is {@link CUSTOM_ID_USAGE.DISPLAY}.
 */

/**
 * @typedef IRecordVersionDetails Contains information about a requirement model and its version model
 * @property {string} typeCode The type code of the root requirement model
 * @property {number} id The ID of the root requirement (e.g.: if this is an FQA or an FQAVersion,
 * it will contain the id of the FQA)
 * @property {string} idField The name of the field that contains the ID of the root requirement
 * (e.g.: if this is an FQA or an FQAVersion, it will be the field that contains the FQA ID, which would be
 * "id" for an FQA or "FQAId" for an FQAVersion)
 * @property {string} modelName The name of the model of root requirement
 * (e.g.: if this is an FQA or an FQAVersion, it will be the "FQA")
 * @property {boolean} isVersionModel If true, the record that was sent into {@link getVersionDetailsFromRecord}
 * is a version of a model.
 * @property {number} [versionId] If the model is a version ({@link isVersionModel} is true), this will contain the version ID of the record
 * @property {string} [versionIdField] If the model is a version ({@link isVersionModel} is true), this will contain the field that holds the version ID of the record
 * @property {string} [versionModelName] If the model is a version ({@link isVersionModel} is true), this will contain the name of the version model (e.g.: as FQAVersion)
 * @property {number} [majorVersion] The major version number
 * @property {number} [minorVersion] The minor version number
 * @property {*} record The record instance itself
 */

/**
 * Indicates how the custom ID must be formatted according to its usage.
 * If used to sorting, it will contain a padded number.
 * @enum {String}
 */
const CUSTOM_ID_USAGE = {
  DISPLAY: "Display",
  SORTING: "Sorting",
};

/**
 * @type {ICustomLabelOptions}
 */
const CUSTOM_LABEL_DEFAULT_OPTIONS = {
  defaultName: "",
  useCustomId: true,
  separator: " - ",
  isTypeCodeTranslated: false,
  usage: CUSTOM_ID_USAGE.DISPLAY,
};

/**
 * This class extracts information from a model or record object and processes them so they can be
 * used to be displayed to the user or to be sent to other layers of the system.
 *
 * For example: it knows how to, given a record, return a formatted string with its type code and display name.
 */
class ModelFormatter extends UtilityExtension {
  /**
   * Retrieves the singleton instance of {@link ModelFormatter}
   * @param [commonUtils] {import("./common_utils")} {@link CommonUtils} passed as a reference, since
   * this class will be used from there and we don't want a circular reference.
   * @returns {ModelFormatter}
   */
  static instance(commonUtils = null) {
    if (!singletonInstance) {
      singletonInstance = new ModelFormatter(commonUtils);
    }
    return singletonInstance;
  }

  /**
   * Retrieves the current instance of the {@link ModelFinder} used to retrieve information about model declarations.
   * @returns {ModelFinder}
   */
  get modelFinder() {
    return this.commonUtils.modelFinder;
  }

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param [options] {ICustomLabelOptions} The options for formatting the custom label.
   * @return {string}
   */
  getRecordCustomLabelForDisplay(record, options = CUSTOM_LABEL_DEFAULT_OPTIONS) {
    record = this.convertToPlainObject(record);
    options = Object.assign({}, CUSTOM_LABEL_DEFAULT_OPTIONS, (options || {}));
    let {defaultName, useCustomId, separator, isTypeCodeTranslated} = options;

    const {id, name} = record;

    // This is a new record (no name or no id). Returns the default name (if exists)
    if ((!name || !id) && defaultName) {
      return defaultName;
    }

    return `${this.getRecordCustomIdForDisplay(record, {useCustomId, isTypeCodeTranslated})}${separator}${name}`;
  }

  /**
   * Gets a label fit for displaying a record for the user (given the record itself)
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param [options] {ICustomLabelOptions} The options for formatting the custom label.
   * @return {string}
   */
  getRecordCustomLabelForDisplayAlternate(record, options = CUSTOM_LABEL_DEFAULT_OPTIONS) {
    record = this.convertToPlainObject(record);
    options = Object.assign({}, CUSTOM_LABEL_DEFAULT_OPTIONS, (options || {}));
    let {defaultName, useCustomId, separator, isTypeCodeTranslated} = options;

    const {id, name} = record;

    // This is a new record (no name or no id). Returns the default name (if exists)
    if ((!name || !id) && defaultName) {
      return defaultName;
    }

    return `${name}${separator}${this.getRecordCustomIdForDisplay(record, {useCustomId, isTypeCodeTranslated})}`;
  }

  /**
   * Gets a combination of type code and ID (or custom id) fit for displaying a record for the user (given the record itself).
   * See {@link getRecordCustomId} for detailed information.
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label.
   * @return {string}
   */
  getRecordCustomIdForDisplay(record, options = CUSTOM_LABEL_DEFAULT_OPTIONS) {
    // noinspection JSValidateTypes
    /**
     * @type {ICustomLabelOptions}
     */
    let actualOptions = Object.assign({}, options || {}, {usage: CUSTOM_ID_USAGE.DISPLAY});
    return this.getRecordCustomId(record, actualOptions);
  }

  /**
   * Gets a combination of type code and ID (or custom id) fit for sorting a record for the user (given the record itself).
   * See {@link getRecordCustomId} for detailed information.
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label.
   * @return {string}
   */
  getRecordCustomIdForSorting(record, options = CUSTOM_LABEL_DEFAULT_OPTIONS) {
    // noinspection JSValidateTypes
    /**
     * Overrides the received options `usage` value to be {@link CUSTOM_ID_USAGE.SORTING}
     * @type {ICustomLabelOptions}
     */
    let actualOptions = Object.assign({}, options || {}, {usage: CUSTOM_ID_USAGE.SORTING});
    return this.getRecordCustomId(record, actualOptions);
  }

  /**
   * Gets a combination of type code and ID (or custom id) fit for displaying a record for the user (given the record itself).
   *
   * This should work like this (in a high level)
   *
   * 1) No custom ID
   *    - Input: Document (ID: 1)
   *    - Output: DOC-1
   *
   * 2) Version object, no custom ID
   *   - Input: DocumentVersion (ID: 49, DocumentId: 1)
   *   - Output: DOC-1
   *
   * 3) Parent object, with custom ID
   *   - Input: Document (ID: 1, CustomID: SOP-001)
   *   - Output: SOP-001
   *
   * 4) Version object, with custom ID
   *   - Input: Document (ID: 49, DocumentId: 1, CustomID: SOP-001)
   *   - Output: SOP-001
   *
   * This method will also try to infer the object type, if it's a version or parent object and the correct ID to show
   * given the values specified in one or more of the following properties from the record object:
   *   - modelName
   *   - typeCode
   *   - type
   * @param record {IEntity|*} A record in the system (such as a Document, FQA, Process Component, or anything)
   * @param options {ICustomLabelOptions} The options for formatting the custom label.
   * @return {string}
   */
  getRecordCustomId(record, options = CUSTOM_LABEL_DEFAULT_OPTIONS) {
    record = this.convertToPlainObject(record);
    options = Object.assign({}, CUSTOM_LABEL_DEFAULT_OPTIONS, (options || {}));

    let {useCustomId, isTypeCodeTranslated, usage} = options;

    let result;
    let customId = useCustomId ? (
      // we need to decide which casing pattern will be the right one
      record.customID
      || record.customId
      || null
    ) : null;

    // Custom ID exists, use it. Otherwise, uses the regular logic.
    if (customId) {
      result = String(customId);
    } else if (isTypeCodeTranslated && record.typeCode && record.id) {
      // If we receive a translated type code and we have all fields set,
      // since we don't have yet support for translation in the backend, we just
      // build and return the ID as without any checking.
      const id = this.formatIdAccordingToUsage(record, usage);
      result = `${record.typeCode}-${id}`;
    } else {
      // If there's no typeCode or modelName, property, this method will try to infer them
      // and add them to the record.
      let fixedRecord = this.getRecordWithModelNameAndTypeCode(record);

      // Now we have the record with the correct model name and type code,
      // we can proceed to retrieving the correct ID.
      // This method will retrieve the record ID from either a root record (e.g.: FQA)
      // or a record version (e.g.: FQAVersion).
      let recordDetails = this.getVersionDetailsFromRecord(fixedRecord);

      const id = this.formatIdAccordingToUsage(recordDetails, usage);
      result = `${recordDetails.typeCode}-${id}`;
    }
    return result;
  }

  /**
   * Retrieves the ID of a record and, if its usage is for sorting, adds leading zeroes to it, so it can be
   * sorted as a string.
   * @param record {IEntity|*} The record that will have its ID retrieved.
   * @param usage {CUSTOM_ID_USAGE} The way it will be used
   * @param size {number} The size the sorting string will have (in case it's used for sorting)
   * @return {string|*}
   */
  formatIdAccordingToUsage(record, usage, size = 6) {
    return usage === CUSTOM_ID_USAGE.SORTING ? String(record.id).padStart(size, "0") : record.id;
  }

  /**
   *  Removes the version suffix from a model name if present.
   *  If not present, returns the original model name.
   *  For example, if we get a "FQAVersion", we remove the "Version" suffix and
   *  return the root model name: "FQA"
   * @param modelName {string} The name of the model to remove the suffix from (if any)
   * @returns {string}
   */
  removeVersionSuffix(modelName) {
    return modelName && modelName.endsWith(VERSION_SUFFIX)
      ? modelName.substring(0, modelName.length - VERSION_SUFFIX.length)
      : modelName;
  }

  /**
   * Retrieves the type code and the model name from a record (when specified), or throws an exception if it cannot.
   * @param record
   * @returns {{modelName: string, typeCode: *}}
   */
  getTypeCodeAndModelNameFromRecord(record) {
    record = this.convertToPlainObject(record);
    let {typeCode, type, modelName, id, name} = record;

    // We don't have a type code, so we need to try to find out what it is.
    const isSet = (name) => name && typeof name === "string" && name.trim();

    // If both the typeCode and modelName are set, we do nothing.
    if (!isSet(typeCode) || !isSet(modelName)) {

      // We have a type code, but we don't have a model name, so we try to retrieve it.
      if (isSet(typeCode) && !isSet(modelName)) {
        modelName = this.modelFinder.findModelNameForTypeCode(typeCode);
      }

      // We have a model name, but we don't have a type code, so we try to retrieve it.
      if (isSet(modelName) && !isSet(typeCode)) {
        let modelToRetrieveTypeCode = this.removeVersionSuffix(modelName);
        typeCode = this.modelFinder.findTypeCodeForModelName(modelToRetrieveTypeCode);
      }

      // So we still don't have a model name nor a type code, but we have a property called "type"
      // This is not the standard, but there are some places where that happens,
      // such as some places that retrieve a custom SQL, like risk tables and the home page.
      if (!isSet(modelName) && !isSet(typeCode) && isSet(type)) {
        // Since the type property may exist and contain something else, we need to check whether it refers to a
        // type code or model name, so we do this trick here:

        // If the "type" property is a model name, it will succeed
        typeCode = this.modelFinder.findTypeCodeForModelName(type);

        if (isSet(typeCode)) {
          // For detectability and to make sure we fix those occurrences, we'll log them
          // with the stack trace, so we can fix later.
          // console.trace("[WARNING] The property type is being used to store a model name: ", type, "Identified type code as: ", typeCode);

          // So the "type" property was a model name, but it may contain a string with the
          // incorrect casing.
          // So, we standardize it by calling the function to get the model name for the type code.
          // This way we ensure it has the right spelling.
          modelName = this.modelFinder.findModelNameForTypeCode(type);
        } else {
          // We didn't get a type code from the "type" property.
          // This means it's not a valid model name, so we try to verify if the "type" property
          // is a valid type code.
          modelName = this.modelFinder.findModelNameForTypeCode(type);

          // So we now managed to get a model name from the "type" property, but it may
          // contain a string with the incorrect casing.
          // So, we standardize it by calling the function to get the type code from the model name.
          // This way we ensure it has the right spelling and casing.
          if (isSet(modelName)) {
            typeCode = this.modelFinder.findTypeCodeForModelName(modelName);
            // For detectability and to make sure we fix those occurrences, we'll log them
            // with the stack trace
            // console.trace("[WARNING] The property type is being used to store a typeCode: ", type, "Identified type code as: ", modelName);
          }
        }
      }

      // If we reach this point and we still didn't figure out the model name or the type code
      // we failed. Let's throw an error.
      if (!isSet(modelName) || !isSet(typeCode)) {
        // We don't have the type code. We don't have a model name. We don't have a type. There's nothing we can do.
        throw new Error(`Unable to find a valid type code or model name in the specified record. ID: ${id}, Name: ${name}, TypeCode: ${typeCode}, ModelName: ${modelName}, Type: ${type}`);
      }
    }
    return {modelName, typeCode};
  }

  /**
   * Receives a record and identifies whether or not it is a requirement root record
   * (e.g: FQA), or a requirement version record (e.g: FQAVersion) and then retrieves information
   * about the root requirement and its version (if it's a version).
   * @param record {{modelName: string, id: number}|*} A record or version record with an ID and a {@link modelName} property.
   * @returns {IRecordVersionDetails}
   */
  getVersionDetailsFromRecord(record) {
    record = this.convertToPlainObject(record);
    let {modelName, id, typeCode} = record;

    let versionInfo = {
      typeCode,
      id: id,
      idField: "id",
      modelName: this.removeVersionSuffix(modelName),
      isVersionModel: modelName.endsWith(VERSION_SUFFIX),
      versionId: undefined,
      versionIdField: undefined,
      versionModelName: undefined,
      minorVersion: record.majorVersion,
      majorVersion: record.minorVersion,
      record,
    };

    // Now we have the requirement model name (let's say FQA, let's get the field FQAId)
    let requirementIdField = this.commonUtils.convertToId(versionInfo.modelName) + "Id";
    let requirementId = record[requirementIdField];

    if (requirementId) {
      versionInfo.isVersionModel = true;
    } else if (!requirementId && typeof record.majorVersion !== "undefined" && record.majorVersion !== null) {
      // If there's no field named, (e.g.: FQAId), we don't give up and we attempt to retrieve the
      // parent model ID field and value using a case insensitive approach (slower, so we only do if not found)

      // Makes a case insensitive search for the record id
      let requirementModelRef = Object.entries(record).find(
        ([key]) => key.toLowerCase() === requirementIdField.toLowerCase()
      );

      // We found a parent model ID (such as FQAId), so we'll treat this object as a version object
      if (requirementModelRef) {
        // Adds the stack trace information to the logs so we can fix the code that is sending this nonstandard object
        // and make this method stricter and simpler in the future.
        console.trace(`[WARNING] There was no version field named "${requirementIdField}", but there was a "${requirementModelRef[0]}" field. This may indicate we need to fix the code to use the proper casing.`);
        requirementIdField = requirementModelRef[0];
        requirementId = requirementModelRef[1];
        versionInfo.isVersionModel = true;
      }

      if (!requirementId) {
        const versions = record[versionInfo.modelName + "Versions"];
        if (versions) {
          const lastVersion = versions.find(version => version.id === record.LastVersionId);
          if (lastVersion) {
            requirementId = lastVersion[versionInfo.modelName + "Id"];
          }
        }
      }
    }

    if (versionInfo.isVersionModel) {
      if (requirementId) {
        // This is a version, so we will build the result using the parent model ID we figured out.
        versionInfo.id = requirementId;
        versionInfo.idField = requirementIdField;
        versionInfo.versionId = id;
        versionInfo.versionIdField = "id";
        versionInfo.versionModelName = versionInfo.modelName + VERSION_SUFFIX;
      } else {
        // This is a version and we didn't figure out any parent model ID. Since we can't use the version id as the
        // record ID (version 49 of FQA 1 is not FQA-49).
        throw new Error(`For version objects, please include the id of the main object as property ${requirementIdField}.`);
      }
    }
    return versionInfo;
  }

  /**
   * Creates a copy of the specified record, ensuring it has properties describing
   * its model name and type code
   * @param record {*}
   * @returns {{modelName: string, typeCode: string, id: number}|*}
   */
  getRecordWithModelNameAndTypeCode(record) {
    record = this.convertToPlainObject(record);
    const recordInfo = this.getTypeCodeAndModelNameFromRecord(record);
    return Object.assign({}, record, recordInfo);
  }

  /**
   * Ensures that the record we're handling is valid and a plain object (if it's a sequelize object, we get the plain object).
   * @param record {IEntity|IEntity[]|*}
   * @param allowFalsy {boolean} If false, throws an error in case the record is null or undefined. The default is false.
   * @returns {IEntity|*}
   */
  convertToPlainObject(record, allowFalsy = false) {
    if (!allowFalsy && !record) {
      throw new TypeError("The parameter 'record' must not be falsy.");
    }

    // If we got an array, we convert each item in the array.
    if (record && Array.isArray(record)) {
      return record.filter(item => !!item).map(item => this.convertToPlainObject(item));
    }

    let result;
    if (record && typeof record.get === "function") {
      // This converts the instance to a plain JavaScript object. See: https://github.com/sequelize/sequelize/issues/5562
      result = record.get({plain: true});

      // adds the model name and type code information to the instance
      let modelName = record.constructor.name;

      if (modelName && modelName === "Object") {
        const versionKey = Object.keys(record).find(key => key.endsWith("Versions"));
        if (versionKey) {
          modelName = versionKey.replace("Versions", "");
        }
      }

      if (modelName && modelName.endsWith("ApprovalRequest")) {
        modelName = modelName.replace("ApprovalRequest", "");
      }

      if (modelName) {
        result.modelName = modelName;
        result.typeCode = this.modelFinder.findTypeCodeForModelName(modelName);
      }
    } else {
      result = record;
    }
    return result;
  }

  /**
   * This returns the full description of a model, using the type code.
   * @param typeCode The model type code.
   * @returns {string}
   */
  getModelFullNameFromTypeCode(typeCode) {
    switch (typeCode) {
      case "FQA":
        return "Final Quality Attribute";
      case "FPA":
        return "Final Performance Attribute";
      case "IQA":
        return "Intermediate Quality Attribute";
      case "IPA":
        return "Intermediate Performance Attribute";
      case "MT":
        return "Materials";
      case "UO":
        return "Unit Operation";
      case "STP":
        return "Step";
      case "PR":
        return "Process";
      case "PP":
        return "Process Parameter";
      case "MA":
        return "Material Attribute";
      case "MTLS":
        return "Material Specification";
      default:
        throw new Error(`Type code ${typeCode} needs to be supported in getModelFullNameFromTypeCode.`);
    }
  }
}

module.exports = {
  ModelFormatter: ModelFormatter,
  CUSTOM_LABEL_DEFAULT_OPTIONS,
};
